<template>
  <section class="content-my-orders">
    <div class="content-my-orders-itens container">
      <div class="content-my-orders-itens-header">
        <h1>{{ $t('my_orders.my_orders') }}</h1>
      </div>
      <div class="content-my-orders-itens-body">
        <div class="content-title">
          <h2>{{ $t('my_orders.order_history') }}</h2>
        </div>
        <div class="content-table">
          <table>
            <thead>
              <tr>
                <th>{{ $t('my_orders.code') }}</th>
                <th>{{ $t('my_orders.date') }}</th>
                <!-- <th>{{ $t('my_orders.shipping_pickup') }}</th> -->
                <th>{{ $t('my_orders.value') }}</th>
                <th>{{ $t('my_orders.payment') }}</th>
                <th>{{ $t('my_orders.requested_situation') }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="loading">
                <tr v-for="n in 2" :key="n">
                  <td><PuSkeleton :count="3" /></td>
                  <td><PuSkeleton :count="3" /></td>
                  <td><PuSkeleton :count="3" /></td>
                  <td><PuSkeleton :count="3" /></td>
                  <td><PuSkeleton :count="3" /></td>
                  <td><PuSkeleton :count="3" /></td>
                  <td><PuSkeleton :count="3" /></td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(order, orderIndex) in orders" :key="orderIndex">
                  <td>
                    <router-link
                      class="content-table-order"
                      :to="{
                        name: 'MyOrdersDetails',
                        params: { id: order.id },
                      }"
                    >
                      #{{ order.id }}
                    </router-link>
                  </td>
                  <td>
                    {{ order.created_at | moment("DD/MM/YYYY") }}
                  </td>
                  <!-- <td>
                    {{ order.type_delivery_name }}
                  </td> -->
                  <td>
                    {{ order.price_total | money }}
                  </td>
                  <td class="">
                    <span
                      class="badge badge-success"
                      :style="{ background: order.payment_status.color }"
                    >
                      {{ order.payment_status.name }}
                    </span>
                  </td>
                  <td class="">
                    <span
                      class="badge badge-success"
                      :style="{ background: order.status.color }"
                    >
                      {{ order.status.name }}
                    </span>
                  </td>
                  <td class="product-remove">
                    <router-link
                      :to="{
                        name: 'orders-details',
                        params: { id: order.id },
                      }"
                      ><i class="fa fa-eye"></i
                    ></router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="btns-div">
          <router-link :to="{ name: 'Home' }" class="btn-continue-shopping">
            <img
              class="img-fluid"
              src="@/assets/img/arrow-left.png"
              alt="arrow left"
              title="arrow left"
            />
            {{ $t('btns_default.continue_shopping') }}
          </router-link>
          <router-link
            :to="{ name: 'MyAccount' }"
            class="btn-continue-shopping"
          >
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user"
              role="img"
            >
              <path
                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
              ></path>
            </svg>
            {{ $t('btns_default.my_account') }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    title: "Minhas encomendas",
  },
  computed: {
    ...mapGetters("Order", ["orders", "loading"]),
  },
  methods: {
    ...mapActions("Order", ["getListOrders"]),
  },
  mounted() {
    this.getListOrders();
  },
};
</script>

<style lang="scss">
.content-my-orders {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      padding: 80px 0px 0px 0px;
      .content-title {
        margin-bottom: 30px;
        text-align: left;
        h2 {
          font-size: 30px;
          font-family: "Font Bold";
          color: var(--main-color5);
          margin-bottom: 0;
          @media (max-width: 576px) {
            font-size: 28px;
          }
        }
      }
      .content-table {
        width: 100%;
        margin-bottom: 50px;
        @media (max-width: 767px) {
          overflow-x: scroll;
          -webkit-overflow-scrolling: touch;
        }
        table {
          width: 100%;
          @media (max-width: 767px) {
            width: 800px;
          }
          thead {
            tr {
              border-top: 1px solid #ebebeb;
              th {
                font-size: 18px;
                font-family: "Font Bold";
                color: var(--main-color5);
                padding: 12px 10px 10px 0;
                text-align: left;
              }
            }
          }
          tbody {
            tr {
              border-top: 1px solid #ebebeb;
              td {
                font-size: 18px;
                font-family: "Font Bold";
                color: var(--main-color4);
                padding: 12px 10px 10px 0;
                text-align: left;
              }
            }
          }
        }
        &-order {
          display: block;
          font-size: 18px;
          font-family: "Font Bold";
          color: var(--main-color5) !important;
          text-decoration: none;
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
      .btns-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn-continue-shopping {
        display: flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
          @media (max-width: 576px) {
            max-width: 15px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--main-color4);
          margin-left: 5px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
          }
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>